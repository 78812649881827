.footer-content {
    background-color: lightgray;
    min-height: 5rem;
}

.footer-row {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 1%
}

.home-background {
    align-items: center;
    display: flex;
    min-height: 95vh;
    background-size: cover;
    width: 100vw;
    margin-left: -15px;
    background-image: url("/src/assets/img/working1.jpeg");
}

.home-card {
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 5%;
    padding-right: 2%;
    margin-top: 20%;
    width: 40%;
    background-color: whitesmoke;
}

.home-text {
    color: #596067;
}

.home-title {
    color: rgba(185, 20, 1, 255);
    text-shadow: 0.1px 0 0 #596067, 0 -0.1px 0 #596067, 0 0.1px 0 #596067, -0.1px 0 0 #596067;
}

.home-content {
    padding: 2%;
    background-color: whitesmoke;
}


.home-content-background {
    background-image: url("/src/assets/img/working1.jpeg");
    background-size: cover;
    width: 100vw;
    padding-left: 5%;
    padding-right: 5%;
    margin-left: -15px;
}

.home-content-background-alt {
    background-color: #2b2b2b;
    width: 100vw;
    padding-left: 5%;
    padding-right: 5%;
    margin-left: -15px;
}

.card-image {
    background-size: cover;
}

.carousel-fixed-height-image {
    height: 60vh !important;
}

.link-button {
    border-width: 0px;
    background-color: transparent;
    padding: 0px;
    outline: none;
}

.link-button:hover {
    color: rgba(185, 20, 1, 255)
}

.link-button:focus {
    outline: none;
    border: none;
}

.fill {
    position: relative;
    height: 50px;
    width: 150px;
    margin: 10px 7px;
    padding: 5px 5px;
    font-weight: 700;
    font-size: 15px;
    letter-spacing: 2px;
    color: rgba(205, 0, 13, 255);
    border: 2px rgba(205, 0, 13, 255) solid;
    text-transform: uppercase;
    outline: 0;
    overflow: hidden;
    background: none;
    z-index: 1;
    cursor: pointer;
    transition: 0.08s ease-in;
    -o-transition: 0.08s ease-in;
    -ms-transition: 0.08s ease-in;
    -moz-transition: 0.08s ease-in;
    -webkit-transition: 0.08s ease-in;
}

.fill:hover {
    color: #383736;
}

.fill:before {
    content: "";
    position: absolute;
    background: rgba(205, 0, 13, 255);
    bottom: 0;
    left: 0;
    right: 0;
    top: 100%;
    z-index: -1;
    -webkit-transition: top 0.09s ease-in;
}

.fill:hover:before {
    top: 0;
}

.separator {
    border-bottom: 1px solid #2b2b2b;
}

.height-full {
    min-height: 95vh;
}

.width-full {
    min-width: 100vw;
    margin-left: -15px;
}

a {
    color: #596067;
    margin: 2px
}

a:hover {
    color: rgba(205, 0, 13, 255);
}

p {
    margin: 2px;
}

.topnav {
    background-color: lightgray;
    position: sticky;
}